import { Logo } from "./whiteLogo";

export default function Footer() {
  return (
    <footer className="bg-gray-50 py-8 border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        {/* Levá část s povinnými informacemi */}
        <div className="text-left space-y-1 text-gray-600">
          <p className="text-sm">Martin Aschermann</p>
          <p className="text-sm">Velké Kunratické 1399/24, Praha 4</p>
          <p className="text-sm">IČ: 21592942</p>
        </div>
        
        {/* Pravá část s velkým fialovým názvem */}
        <div className="flex lg:flex-1 justify-end">
          <a href="#home" className="text-2xl font-bold tracking-tight text-indigo-600 sm:text-3xl">
            <Logo />
          </a>
        </div>
      </div>
    </footer>
  );
}
