import { useState } from "react"

const testimonials = [
  {
    quote:
      "“Na spolupráci s Martinem Aschermannem (zakladatel PixelDev) oceňuji zejména profesionální, ochotný a kreativní přístup k tvorbě našich webových stránek, které jsou přehledné, funkční a designově velice vydařené. Admin sekce je uživatelsky přívětivá a intuitivní a všechny na míru navržené aplikace fungují bezchybně. Byla to skvělá volba a těším se na další spolupráci.”",
    name: "Lukáš Kamas",
    title: "Spoluzakladatel & Vedoucí lektorského týmu @ Takoda",
    logo: `${process.env.PUBLIC_URL}/takoda_logo.png`,
    image:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    quote:
      "“Na spolupráci s PixelDev se nám líbí rychlost, zodpovědnost a ochota společně konzultovat řešení, abychom naši platformu neustále rozvíjeli.”",
    name: "Ondřej Žoch",
    title: "Spoluzakladatel Fuglio",
    logo: `${process.env.PUBLIC_URL}/fuglio.png`,
  },
  {
    quote:
      "“S Martinom (zakladatel PixelDev) sa spolupracovalo skvelo z hľadiska komunikácie a prístupu. Vždy všetko na čom sme sa dohodli bolo implementované včas a bez chýb. Veľmi veľká ochota riešiť všetky naše pripomienky ale aj vniesť vlastný pohľad na problematiku. Klobúk dole pred Martinom a veľký palec hore! ”",
    name: "Tomáš Hájíček",
    title: "Ex-prezident @ ESN VŠE Prague",
    logo: `${process.env.PUBLIC_URL}/esn-logo.png`,
  },
]

export default function Testimonials() {
  const [activeIndex, setActiveIndex] = useState(0)
  const testimonial = testimonials[activeIndex]

  return (
    <section className="bg-white px-6 py-12 sm:py-12 lg:px-8">
      <div className="mx-auto max-w-3xl lg:max-w-4xl">
        <img
          alt="Logo"
          src={testimonial.logo}
          className="mx-auto w-36"
        />

        <figure className="mt-10 transition-all duration-300 ease-in-out">
          <blockquote className="text-center text-xl/8 font-semibold text-gray-900 sm:text-2xl/9">
            <p>{testimonial.quote}</p>
          </blockquote>
          <figcaption className="mt-10">
            {/* <img
              alt={testimonial.name}
              src={testimonial.image}
              className="mx-auto size-10 rounded-full"
            /> */}
            <div className="mt-4 flex items-center justify-center space-x-3 text-base">
              <div className="font-semibold text-gray-900">{testimonial.name}</div>
              <svg width={3} height={3} viewBox="0 0 2 2" aria-hidden="true" className="fill-gray-900">
                <circle r={1} cx={1} cy={1} />
              </svg>
              <div className="text-gray-600">{testimonial.title}</div>
            </div>
          </figcaption>

          {/* Tečky pro přepínání */}
          <div className="mt-10 flex justify-center space-x-2">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setActiveIndex(index)}
                className={`h-2 w-2 rounded-full transition-colors ${
                  index === activeIndex ? "bg-gray-900" : "bg-gray-300"
                }`}
                aria-label={`Zobrazit testimonial ${index + 1}`}
              />
            ))}
          </div>
        </figure>
      </div>
    </section>
  )
}
